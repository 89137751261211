  .container {
    position: relative;
    cursor: pointer;
    display: flex;
  }
  
  .image {
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .image {
    opacity: 0.3;
  }
  
  .container:hover .middle {
    opacity: 1;
  }
  
  .text {
    color: black;
    font-size: 16px;
    padding: 16px 32px;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: black;
  }

  .containerPlus {
    position: relative;
    text-align: center;
    color: #222;
    cursor: pointer;
  }

  .modalContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
  }

  .modalDownload {
    position: relative;
    width: 400px;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
  }
  
  .modalImage {
    display: inline-block;
    width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .portrait {
    display: inline-block;
    width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .landscape {
    display: inline-block;
    width:100%;
    position: absolute;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .modalImages {
    display: inline-block;
    width:100%;
    position: absolute;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .modalCentered {
    position: absolute;
    width: 100%;
    font-size: 12px;
    top:72%;
    color: white;
    display:flex;
    background-color: rgba(52, 52, 52, 0.3);
  }

  .modalCenteredDownload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    color: white;
    display:flex;
    background-color: rgba(52, 52, 52, 0.3);
  }

  .bottom {
    bottom: 0px;
  }

  .top {
    top:72%;
  }

  .modalCenteredDiv {
    position: absolute;
    width: 100%;
    background-color: #343434;
    opacity: 0.4;
    color: white;
    height: 100%;
    display:flex;
  }

  .gridcontainer {
    width: auto;
    max-width: 450px;
    align-content: center;
  }

  .fowardarrowCentered {
    position: absolute;
    width: 100%;
    color: white;
    top: 50%;
    float: right;
  }

  .details {
    position: absolute;
    top:50%;
    right: 0px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .modalContainer {
      position: relative;
      width: 100%;
      height: 100vh;
    }
    .modalImage {
      display: block;
      width: 100%;
    }
    .gridcontainer {
      width: auto;
      max-width: 300px;
    }
    .fowardarrowCentered {
      position: absolute;
      width: 100%;
      color: white;
      top: 50%;
      float: right;
    }
  }